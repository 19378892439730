<template>
  <div class="faq page">
    <div class="bg d-none d-lg-block">
      <GetStarted v-bind:hasBadge="false" class="mx-auto"/>
    </div>
    <img src="../assets/question-mark.svg" alt="Question Mark" class="question-mark">
    <div class="container">
    <div class="row align-item">
      <div class="col-5 d-none d-lg-block"></div>
      <div class="col-12 col-lg-6 mt-lg-5">
        <div class="mt-5 mb-3 ml-2 ml-lg-3">
          <h1 class="text-title-1 text-left">Debt Settlement FAQ</h1>
          <p class="subtitle text-gray text-left">Here are some of the most common questions people ask us. It is a great idea to learn about all of your options.</p>
        </div>
        <VueFaqAccordion
        :items="myItems"
        :activeColor="activeColor"
        />
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import VueFaqAccordion from 'vue-faq-accordion'
import GetStarted from '@/components/GetStarted.vue'

export default {
    components: {
    VueFaqAccordion,
    GetStarted
  },
  data () {
    return {
      myItems: [
          {
            title: 'What is Debt Settlement?',
            value: 'A debt settlement is when a borrower settles a debt for less than the full amount owed. The lender must agree to accept less than the full amount owed and the arrangement is then indicated on the borrower’s credit record. Usually, people use the services of a professional debt settlement negotiator and in some rare cases this negotiator may also be certified as a debt counselor.',
          },
          {
            title: 'What is Debt Consolidation?',
            value: 'It is challenging for a consumer facing overwhelming debts to be approved for a debt consolidation loan. However, there are some lenders that do offer debt consolidation loans, which grant the advantages of debt consolidation along with the benefits that come from professional counseling services. Even those consumers who are able to get a consolidation loan or consolidate their debts through balance transfers could benefit from a free debt counseling session, since debt consolidation by itself does not offer the education and assistance a counselor provides. <a href="/learn/learn-more-about-debt-consolidation">Learn more about debt consolidation</a>',
          },
          {
            title: 'What is Debt Management?',
            value: 'A Debt Management Plan (DMP) is an affordable debt relief program that involves working with a financial coach to create a personalized budget. The coach works with you and your creditors to create realistic monthly payment plans, minimize fees, stop collection calls and teaches you how to pay off debt more efficiently.',
          },
          {
            title: 'What about Bankruptcy?',
            value: 'By law, anyone seeking to declare bankruptcy must seek <a href="https://www.bkhelp.org/">bankruptcy counseling</a> before they officially file. After bankruptcy is legally declared, they must also complete an education course before any of their debt can be discharged. As required by law, any organization that provides pre-bankruptcy counseling & education must be approved by the EOUST (Executive Office for US Trustees). As an EOUST approved organization, credit.org’s certified coaches are available to provide the information and education you need to comply with bankruptcy laws and improve your path forward.',
          }
          ,
          {
            title: 'What is right for me? ',
            value: 'Getting out of debt removes a huge burden off your shoulders.  Every situation is different and it is important you understand your options.  You should talk to a non-bias, no cost financial coach that can help you understand, decide and select the right solution.  Credit.org offers free access to financial coaches that can help you right now. As long as you use a *credentialed counseling organization like credit.org, you can feel secure that you’re getting the best (and most discrete) service. (*Read <a href="/learn/how-to-know-if-a-credit-counseling-agency-is-legitimate">“How to know if a Credit Counseling Agency is Legitimate“)</a>.',
          }
        ],
        activeColor: '#1968FC',
        borderColor: ''
    }

  }
}
</script>
<style lang="scss">
  .faq.page .container {
    margin-top: 30px;
  }

    .question-mark {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 80px;
    }

  section.faq {
    * {
      border: none !important;
      padding-left: 0 !important;
    }

    .accordion__title-text {
      font-weight: 600;

    }

    .accordion__title {
      border-bottom: 2px solid lightgrey !important;
    }

    .accordion__title_active {
      border-bottom: none !important;
    }

    .accordion__title_active+div {
      border-bottom: 2px solid theme-colors("primary") !important;
    }
  }

  @media only screen and (min-width: 992px) {

    .faq.page .container {
      margin-top: 100px;
    }

    .question-mark {
      top: 30px;
      right: 50px;
      width: 160px;
    }

    .faq.page .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 40%;
      height: 100%;
      background-size: cover;
      opacity: 0.9;
      border-right: 4px solid #f0f0f0;
      background-color: #1968FC;
      align-items: center;
      padding-top: 150px;
      z-index: 1;
    }

    .accordion__toggle-button {
      display: block;
    }

    section.faq .accordion__title-text,
    section.faq .accordion__value {
      padding-left: 30px !important;
    }

    .text-title-1 {
      font-size: 2.2rem;
      line-height: 2rem;
    }

    .subtitle {
      font-size: 1.1rem;
      line-height: 1.5rem;
      font-weight: 500;
      max-width: 500px;
    }
  }
</style>




